import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

const Bielenda = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #A96271, #C97687, #D77B8D, #C97687, #A96271 )",
        icons: "#e27f94",
        navClass: "bielenda",
      }}
      seo={{
        title: "Bielenda",
        headerTitle: "bielenda",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/bielenda_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/bielenda/",
      }}
    >
      <PortfolioHeader name="bielenda" height="90" />
      <section className="container-fluid bielenda_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Bielenda</h1>
              <ul>
                <li>Logo </li>
                <li>Księga znaku</li>
              </ul>
            </div>
          </div>

          <div className="col-md-6">
            <div className="inner">
              <p>
                BIELENDA Kosmetyki Naturalne to istniejący od 1990 roku
                producent najwyższej jakości naturalnych kosmetyków do
                pielęgnacji twarzy i ciała.
              </p>
              <p>
                Bielenda jest jedną z najbardziej rozpoznawalnych i cenionych
                marek na polskim rynku kosmetycznym, która eksportuje swoje
                produkty m.in. do Francji, Rumunii, Hiszpanii, Grecji, Estonii,
                Łotwy, Litwy, Holandii, Szwecji, Wielkiej Brytanii, Niemiec,
                Portugalii, Korei, Zjednoczonych Emiratów Arabskich oraz na
                Cypr.
              </p>
              <p>
                Wraz z poszerzaniem oferty produktowej konieczne okazało się
                odświeżenie dotychczasowego znaku Bielenda. Głównymi założeniami
                refreshu było zapewnienie świeżości oraz skojarzeń z naturą.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid bielenda_section_3">
        <div className="row">
          <div className="col-md-6 col_1">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/bielenda_main_1.png")}
              alt=""
            />
            <div className="img_wrapper">
              <img
                className="img-fluid"
                src={require("../../assets/img/portfolio/bielenda_main_2.png")}
                alt=""
              />
            </div>
          </div>
          <div className="col-md-4 offset-md-2 col_2">
            <h3>Logo</h3>
            <div className="row">
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>Grey CMYK</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 31 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 25 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 25 : 0}
                      />
                      <span>0</span>
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>Green CMYK</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 56 : 0}
                      />
                      <CountUp start={0} duration={3} end={isVisible ? 4 : 0} />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 100 : 0}
                      />
                      <span>0</span>
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>RGB</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 177 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 178 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 179 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>RGB</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 127 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 186 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 35 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>b1b2b3</h5>
              </div>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>7fba23</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid bielenda_section_4">
        <img
          className="img-fluid"
          src={require("../../assets/img/portfolio/bielenda_main_3.png")}
          alt=""
        />
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Bielenda;
